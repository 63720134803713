/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable no-console */
import { useMemo, useState } from 'react';
import { Auth, JS } from 'aws-amplify';
import { QueryResult } from '@apollo/react-common';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-boost';
import { currentAgentQuery } from '@/graphql/queries';
import { updateUserProfile } from '@/graphql/mutations';

type BrokerCredit = {
  brokerName: string;
  brokerID: string;
  credits: number;
};

type Dashboard = {
  name: string;
  code: string;
  filters: string[];
};

type UserType = {
  id: string;
  agentID: string;
  subID: string;
  corpID: string;
  // marketplace: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  userID: string;
  networkID: string;
  agentApproved: boolean;
  callerReadyAccountStatus: string;
  lastCallerReadyChargeResult: string;
  callerReadyStatus: string;
  lastCallerReadyCharge: string;
  agentCallerReadyID: string;
  autoFundSettings: {
    threshold: number;
    amount: number;
    enabled: boolean;
  };
  role: string;
  network: {
    corpID: string;
    brokerID: string;
    networkName: string;
    supportEmail: string;
    allowedStates: string[];
    availibleCRMs: JSON;
    agentDashboards: Dashboard[];
    showLastResults: boolean;
    vendorLayerPrices: {
      blockDisconnected: number;
      blockDuplicates: number;
      enhanceProfile: number;
    };
    services: {
      callerready: {
        fee: {
          description: string;
          amount: number;
        };
        minimumAccountBalance: number;
      };
    };
  };
  flags: {
    allowManagerDashboard: boolean;
    allowCallerReady: boolean;
    allowMarketplaceBrokering: boolean;
    allowBrokerRejects: boolean;
    allowCrossNetworkTransfers: boolean;
    allowMyAgents: boolean;
    allowBeta: boolean;
    allowVendor: boolean;
    allowVendors: boolean;
    allowSelfEdit: boolean;
    canDeliverDuplicateLeads: boolean;
    allowProportionalDestinations: boolean;
    allowCreditSummary: boolean;
    allowCustomAPIs: boolean;
    allowBulkSales: boolean;
    allowRegisterForOutboundTransfers: boolean;
    allowSameDestination: boolean;
    allowSideline: boolean;
    allowMyAgentEmails: boolean;
  };
  crmProfiles: any;
  totalCredit: number;
  contributedCredit: number;
  otherBrokerCredits: BrokerCredit[];
  sidelineCampaigns: string[];
  leadBuyInAmount: number;
  // creditBuckets: any
};

interface UseUserResult extends Omit<QueryResult, 'data'> {
  user: UserType;
  loading: boolean;
  error?: ApolloError;
  firstTimeSetup: boolean;
}

export default (): UseUserResult => {
  const [firstTimeSetup, setFirstTimeSetup] = useState(false);
  const [createUser, { client }] = useMutation(updateUserProfile, {
    update: async () => {
      setTimeout(async () => {
        await client!.query({
          query: currentAgentQuery,
        });
        setFirstTimeSetup(false);
      }, 5000); // TODO - wait a certain time before refetching so ES has time to sync
    },
  });

  const setupUser = async (): Promise<any> => {
    try {
      const currentUser = await Auth.currentUserInfo();

      const {
        attributes: {
          'custom:buyer': buyerID,
          'custom:company': companyID,
          'custom:corp': corpID,
          // 'custom:marketplace': marketplace,
          'custom:merchant': customerID,
          'custom:network': networkID,
          'custom:managerIDs': managerIDs = '[]',
          'custom:mainGroupID': mainGroupID,
          email,
          family_name: lastName,
          given_name: firstName,
          phone_number: phone,
          sub: subID,
        },
        id: userID,
      } = currentUser;

      createUser({
        variables: {
          input: {
            agentID: userID,
            subID,
            buyerID,
            companyID,
            corpID,
            // marketplace,
            customerID,
            networkID,
            managerIDs: JSON.parse(managerIDs),
            email,
            lastName,
            firstName,
            phone,
            mainGroupID,
          },
        },
      });
    } catch (error_) {
      console.log(error_);
    }
  };

  const { data, loading, error, ...rest } = useQuery(currentAgentQuery, {
    onError: (error) => {
      if (error.graphQLErrors?.[0]?.message === 'Agent not found.') {
        setFirstTimeSetup(true);
      } else {
        throw new Error('Something went wrong');
      }

      setupUser();
    },
  });

  const user = useMemo(() => (data ? data?.userCurrent : undefined), [data]);

  return {
    user,
    loading,
    error,
    firstTimeSetup,
    ...rest,
  };
};
