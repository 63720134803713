/* eslint-disable @typescript-eslint/camelcase */
import envVar, { setEnvironments } from '@availity/env-var';

const environments = {
  local: ['127.0.0.1', 'localhost'],
  test: [
    /dev.leadarena.com/,
    /dev.sonicleads.com/,
    /dev.ushamarketplace.com/,
    /dev.leadtopia.com/,
    /dev.americafirstleads.com/,
  ],
  stage: [
    /stage.ushamarketplace.com/,
    /stage.americafirstleads.com/,
    /stage.leadtopia.com/,
  ],
  prod: [
    /app.leadarena.com/,
    /app.ushamarketplace.com/,
    /app.sonicleads.com/,
    /app.leadtopia.com/,
    /app.americafirstleads.com/,
  ],
  beta: [/beta.leadarena.com/, /beta.ushamarketplace.com/],
  darkpod: [/darkpod.leadarena.com/, /darkpod.ushamarketplace.com/],
};

type CustomEnv = keyof typeof environments;

setEnvironments(environments, true);

const devAwsMobile = {
  aws_project_region: 'us-east-1',
  aws_content_delivery_bucket:
    'sonic-marketplace-20190414012556-hostingbucket-dev',
  aws_content_delivery_bucket_region: 'us-east-1',
  aws_content_delivery_url: 'https://dgarhdt3o9zlk.cloudfront.net',
  aws_cognito_identity_pool_id:
    'us-east-1:1c500333-d67b-40fe-b596-4d22eb319ee2',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_VxaRaMrPy',
  aws_user_pools_web_client_id: '1d3nppplk4sqp1ba1coten94s0',
  oauth: {},
  aws_cloud_logic_custom: [
    {
      name: 'MarketPlace',
      endpoint: 'https://2fo5gtgkh6.execute-api.us-east-1.amazonaws.com/dev',
      region: 'us-east-1',
    },
  ],
};

const testEnv = {
  notificationsUri: 'https://ping-dev.ushamarketplace.com',
  subScriptionUri: 'wss://subscriptions-dev.leadarena.com/subscriptions',
  iframeUrl: 'https://dev.ushamarketplace.com/dashboard/index.html',
  sonicIframeUrl:
    'https://search-sonic-public-hxpqpqo22jq3glsfkw2oqdtoxq.us-east-1.es.amazonaws.com/_plugin/kibana/app/kibana#/dashboard/84ddec90-d0d8-11e8-8627-114b46fb9795?embed=true&_g=(refreshInterval%3A(pause%3A!f%2Cvalue%3A300000)%2Ctime%3A(from%3Anow%2Fd%2Cmode%3Aquick%2Cto%3Anow%2Fd))&token=eyJraWQiOiIweVhDMUpLbVRmd1lTWW45YkQrR25LRVhrYXRpclhXR29QaEI2Qyt1dVVjPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiJhM2E1NmNlNC1mMDEyLTQ2NTEtYmUxZi0xZmU1NDA1MmZjMDYiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiY3VzdG9tOmNvcnAiOiJ1c2hhIiwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLnVzLWVhc3QtMS5hbWF6b25hd3MuY29tXC91cy1lYXN0LTFfOXFFakllcnBMIiwiY29nbml0bzp1c2VybmFtZSI6InNldGguZG9ybmVAdXNoYWR2aXNvcnMuY29tIiwiZ2l2ZW5fbmFtZSI6IlNldGgiLCJjdXN0b206Y29tcGFueSI6InVzaGEiLCJhdWQiOiI2bGU0aHZhdWVkb2dzMmg3OXIxaXY5NDA5OCIsImN1c3RvbTptZXJjaGFudCI6ImN1c19EWThnWjFlWjZmbkh3NiIsImN1c3RvbTpuZXR3b3JrIjoiODcwZGUwODMtY2VmYy00ZjM5LWIyODYtNmYxZTVhNThiYjEzIiwiZXZlbnRfaWQiOiJlNzgwNDIwMS1mZDljLTQxNDItYTUxMi1mY2VhOTkzN2EzNjciLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTYxNzIyNjk5NCwicGhvbmVfbnVtYmVyIjoiKzE3ODY1ODY0MjcxIiwiZXhwIjoxNjE3MjMwNTk0LCJjdXN0b206YnV5ZXIiOiI2NTU3NDYxZi0xZGY5LTRhMzctOTFiYy04ZmI1ZGRkYmEyZWUiLCJpYXQiOjE2MTcyMjY5OTQsImZhbWlseV9uYW1lIjoiRG9ybmUiLCJlbWFpbCI6InNldGguZG9ybmVAdXNoYWR2aXNvcnMuY29tIn0.DGigrM6P12LTx68r9rNhx8azJ39DkRzyWJ8YFRL0Dqw4lrWaTrCtUgIcRbqAqOoojmbe7kQ7I6gS3OA74BQQE3eKB9cHg0D2trAeTNfrk22NcWuT8-iQ5khRHOR7uRQzY-L4rYXKiqVMl-JN7z3rumRbZIR9SckKXu0z3W6c0KVGuCY-_TV4suMgDZnPL65qIO6gtn-0oE6kJQu5HDESPkoVHp2ZKLx0kOj0qWDlM5DUXcLfEyOo8wWlctLYEvspE_hq4bNU8ZSLwnCdj8WfM4iTGCAyaTSL0GO-cGBqocS0Ytv_zEshKeUbRd282dNu6SoSLpl1fG1mh3BNo2yG-A',
  env: 'dev',
  sonicStripeKey: 'pk_test_ej9Wqulrfr6LgzmKMd6AMgnN',
  ushaStripeKey: 'pk_test_Tcg7G4Tx2RVI94ohrl5AkhYW00sNTJV2j3',
  leadtopiaStripeKey:
    'pk_test_51IWlmYHL2luZ2VEkP1CtnFwYppgwGBEOHakHSGEGBHict1Gc0IzzuRraUM84IL6iEXlNBIRhDjhpHYK5FvW3EnN7004VMdtY1i',
  americafirstStripeKey: 'pk_test_ej9Wqulrfr6LgzmKMd6AMgnN',
  AUTO_FUND_MINIMUM_AMOUNT: 2000,
  AUTO_FUND_MINIMUM_THRESHOLD: 2000,
  awsmobile: devAwsMobile,
  agentCount: 50,
  extensionId: 'hodpknlchegbfgelldlnmmjbhllklaga',
  goHighLevelClientId: '664a5b2c8476c31dbae95ba4-lwexxvdn',
};

const prodEnv = {
  agentCount: 50,
  extensionId: 'jjpciejejefldapaianmanbjkbainffj',
  goHighLevelClientId: '664a5b2c8476c31dbae95ba4-lwgfipss',
  subScriptionUri: 'wss://subscriptions-prod.leadarena.com/subscriptions',
  env: 'prod',
  notificationsUri: 'https://ping.ushamarketplace.com',
  sonicIframeUrl:
    'https://search-sonic-public-hxpqpqo22jq3glsfkw2oqdtoxq.us-east-1.es.amazonaws.com/_plugin/kibana/app/kibana#/dashboard/84ddec90-d0d8-11e8-8627-114b46fb9795?embed=true&_g=(refreshInterval%3A(pause%3A!f%2Cvalue%3A300000)%2Ctime%3A(from%3Anow%2Fd%2Cmode%3Aquick%2Cto%3Anow%2Fd))&token=eyJraWQiOiIweVhDMUpLbVRmd1lTWW45YkQrR25LRVhrYXRpclhXR29QaEI2Qyt1dVVjPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiJhM2E1NmNlNC1mMDEyLTQ2NTEtYmUxZi0xZmU1NDA1MmZjMDYiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiY3VzdG9tOmNvcnAiOiJ1c2hhIiwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLnVzLWVhc3QtMS5hbWF6b25hd3MuY29tXC91cy1lYXN0LTFfOXFFakllcnBMIiwiY29nbml0bzp1c2VybmFtZSI6InNldGguZG9ybmVAdXNoYWR2aXNvcnMuY29tIiwiZ2l2ZW5fbmFtZSI6IlNldGgiLCJjdXN0b206Y29tcGFueSI6InVzaGEiLCJhdWQiOiI2bGU0aHZhdWVkb2dzMmg3OXIxaXY5NDA5OCIsImN1c3RvbTptZXJjaGFudCI6ImN1c19EWThnWjFlWjZmbkh3NiIsImN1c3RvbTpuZXR3b3JrIjoiODcwZGUwODMtY2VmYy00ZjM5LWIyODYtNmYxZTVhNThiYjEzIiwiZXZlbnRfaWQiOiJlNzgwNDIwMS1mZDljLTQxNDItYTUxMi1mY2VhOTkzN2EzNjciLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTYxNzIyNjk5NCwicGhvbmVfbnVtYmVyIjoiKzE3ODY1ODY0MjcxIiwiZXhwIjoxNjE3MjMwNTk0LCJjdXN0b206YnV5ZXIiOiI2NTU3NDYxZi0xZGY5LTRhMzctOTFiYy04ZmI1ZGRkYmEyZWUiLCJpYXQiOjE2MTcyMjY5OTQsImZhbWlseV9uYW1lIjoiRG9ybmUiLCJlbWFpbCI6InNldGguZG9ybmVAdXNoYWR2aXNvcnMuY29tIn0.DGigrM6P12LTx68r9rNhx8azJ39DkRzyWJ8YFRL0Dqw4lrWaTrCtUgIcRbqAqOoojmbe7kQ7I6gS3OA74BQQE3eKB9cHg0D2trAeTNfrk22NcWuT8-iQ5khRHOR7uRQzY-L4rYXKiqVMl-JN7z3rumRbZIR9SckKXu0z3W6c0KVGuCY-_TV4suMgDZnPL65qIO6gtn-0oE6kJQu5HDESPkoVHp2ZKLx0kOj0qWDlM5DUXcLfEyOo8wWlctLYEvspE_hq4bNU8ZSLwnCdj8WfM4iTGCAyaTSL0GO-cGBqocS0Ytv_zEshKeUbRd282dNu6SoSLpl1fG1mh3BNo2yG-A',
  sonicStripeKey: 'pk_live_1tOiKHMKJLf43wto6nytPRkY',
  ushaStripeKey: 'pk_live_Q3RPHzB6abBKpB0xVDu1yCOq00tZWEsvWq',
  leadtopiaStripeKey:
    'pk_live_51IWlmYHL2luZ2VEk6aelKu6OxxyZRQjlID3UlesvLRkMT3qpmvlaCGh6h0nn3CUl9VshH74qIitN7PiNuvNssaC400EX220mS9',
  americafirstStripeKey:
    'pk_live_51NBJskKfi9LImb6Ne1YH2qb63FwPqSBb4nUWzqvYEPzAdksmmMXD6jvWONrWl1sDpfzgxrl5yj8KMhY99l3lcD5N00lJ5yzUFw',
  AUTO_FUND_MINIMUM_AMOUNT: 2000,
  AUTO_FUND_MINIMUM_THRESHOLD: 2000,
  awsmobile: {
    aws_project_region: 'us-east-1',
    aws_content_delivery_bucket:
      'sonic-marketplace-20190414012556-hostingbucket-prod',
    aws_content_delivery_bucket_region: 'us-east-1',
    aws_content_delivery_url: 'https://d9uoo0d8d1rlk.cloudfront.net',
    aws_cognito_identity_pool_id:
      'us-east-1:80f5c276-cd8a-48fc-ac14-c31df7533667',
    aws_cognito_region: 'us-east-1',
    aws_user_pools_id: 'us-east-1_SWmFzvnku',
    aws_user_pools_web_client_id: '5dromsrnopienmqa83ba4n927h',
    oauth: {},
    aws_cloud_logic_custom: [
      {
        name: 'MarketPlace',
        endpoint: 'https://poh3jvlrri.execute-api.us-east-1.amazonaws.com/prod',
        region: 'us-east-1',
      },
    ],
  },
};

const vars = envVar(
  {
    local: {
      ...testEnv,
      uri:
        'https://40tykjcfq5.execute-api.us-east-1.amazonaws.com/stage/portal',
      iframeUrl: 'https://stage.ushamarketplace.com/dashboard/index.html',
      agentCount: 5,
    },
    stage: {
      ...testEnv,
      uri:
        'https://40tykjcfq5.execute-api.us-east-1.amazonaws.com/stage/portal',
      iframeUrl: 'https://stage.ushamarketplace.com/dashboard/index.html',
    },
    prod: {
      ...prodEnv,
      uri: 'https://f7sexx0nb0.execute-api.us-east-1.amazonaws.com/prod/portal',
      iframeUrl: 'https://app.ushamarketplace.com/dashboard/index.html',
      env: 'prod',
    },
    beta: {
      ...prodEnv,
      env: 'prod',
      uri: 'https://f7sexx0nb0.execute-api.us-east-1.amazonaws.com/beta/portal',
      iframeUrl: 'https://beta.ushamarketplace.com/dashboard/index.html',
    },
    darkpod: {
      ...prodEnv,
      env: 'prod',
      uri:
        'https://f7sexx0nb0.execute-api.us-east-1.amazonaws.com/darkpod/portal',
      iframeUrl: 'https://darkpod.ushamarketplace.com/dashboard/index.html',
    },
  },
  undefined,
  {
    ...testEnv,
    uri: 'https://40tykjcfq5.execute-api.us-east-1.amazonaws.com/dev/portal',
    iframeUrl: 'https://dev.ushamarketplace.com/dashboard/index.html',
  }
);

export default vars;
